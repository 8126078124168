import {
  TSetSearchParamsProp,
  useSearchParams,
} from "src/router/useSearchParams"

export type TParadiseDevicesFilters = {
  devices_offset: number | null
  devices_description: string | null
  devices_include_inactive: boolean | null
  devices_offline: boolean | null
  devices_hardwareVersion: number | null
  devices_id: string | null
  devices_mac: string | null
  devices_firmware: string | null
  devices_tag: string | null
}

export function useParadiseDevicesFilter(): {
  filter: TParadiseDevicesFilters
  setFilter: TSetSearchParamsProp<TParadiseDevicesFilters>
  setOffset: (offset: number) => void
  offset: number
} {
  const {
    searchParams: filter,
    setSearchParams: setFilter,
    setSearchParamsAll,
  } = useSearchParams({
    keys: [
      {
        key: "devices_offset",
        type: "number",
      },
      {
        key: "devices_description",
        type: "string",
      },
      {
        key: "devices_include_inactive",
        type: "boolean",
      },
      {
        key: "devices_offline",
        type: "boolean",
        acceptFalsy: true,
      },
      {
        key: "devices_hardwareVersion",
        type: "number",
      },
      {
        key: "devices_id",
        type: "string",
      },
      {
        key: "devices_mac",
        type: "string",
      },
      {
        key: "devices_firmware",
        type: "string",
      },
      {
        key: "devices_tag",
        type: "string",
      },
    ],
  })

  return {
    filter,
    setFilter: (key, value) => {
      if (key === "devices_offset") {
        return
      }

      // Make sure we reset paging on filter
      setSearchParamsAll({
        [key]: value,
        devices_offset: 0,
      })
    },
    setOffset: (offset: number) => {
      setFilter("devices_offset", offset)
    },
    offset: filter.devices_offset || 0,
  }
}
