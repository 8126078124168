import { useState } from "react"
import styled from "styled-components"

import { FormControlLabel, Radio, RadioGroup, Switch } from "@material-ui/core"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { TParadiseDevicesFilters } from "src/components/Paradise/useParadiseDevicesFilter"
import { useFetchParadiseHardwareVersions } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { TSetSearchParamsProp } from "src/router/useSearchParams"
import { Combobox } from "src/ui/Combobox/Combobox"
import { Filter } from "src/ui/Filter/Filter"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseDevicesFilters({
  filter,
  setFilter,
  showFirmwareFilter,
  showMacAddressFilter,
  showTagFilter,
}: {
  filter: TParadiseDevicesFilters
  setFilter: TSetSearchParamsProp<TParadiseDevicesFilters>
  showFirmwareFilter?: boolean
  showMacAddressFilter?: boolean
  showTagFilter?: boolean
}) {
  const [searchedVersionNumber, setSearchedVersionNumber] = useState("")

  const fetchHardwareVersions = useFetchParadiseHardwareVersions({
    options: {
      select: (data) => {
        return data.filter((v) => {
          const lowerSearch = searchedVersionNumber.toLowerCase()

          return (
            v.name.toLowerCase().includes(lowerSearch) ||
            v.version_number.toString().toLowerCase().includes(lowerSearch)
          )
        })
      },
    },
  })
  const hardwareVersions = fetchHardwareVersions.data

  const selectedHardwareVersion = hardwareVersions?.find(
    (hw) => hw.version_number === filter.devices_hardwareVersion
  )
  return (
    <FilterWrapper>
      <Filter
        filters={[
          {
            id: "description",
            active: !!filter.devices_description,
            label: "Description",
            value: filter.devices_description || "",
            render: (
              <FilterContent>
                <SearchFilter
                  initialValue={filter.devices_description ?? undefined}
                  placeholder="Serach by description"
                  onChange={(v) => setFilter("devices_description", v)}
                />
              </FilterContent>
            ),
            alwaysVisible: true,
            removeLabel: "Reset",
            onRemove: () => {
              setFilter("devices_description", null)
            },
          },
          {
            id: "device_id",
            active: !!filter.devices_id,
            label: "Device ID",
            value: filter.devices_id || "",
            render: (
              <FilterContent>
                <SearchFilter
                  initialValue={filter.devices_id ?? undefined}
                  placeholder="Serach by id"
                  onChange={(v) => setFilter("devices_id", v)}
                />
                <MText variant="bodyS">Make sure to type in the exact ID</MText>
              </FilterContent>
            ),
            onRemove: () => {
              setFilter("devices_id", null)
            },
          },
          {
            id: "device_mac",
            active: !!filter.devices_mac,
            label: "MAC address",
            value: filter.devices_mac || "",
            render: (
              <FilterContent>
                {showMacAddressFilter ? (
                  <SearchFilter
                    initialValue={filter.devices_mac ?? undefined}
                    placeholder="Serach by MAC address"
                    onChange={(v) => setFilter("devices_mac", v)}
                  />
                ) : (
                  <MText>Not supported</MText>
                )}
              </FilterContent>
            ),
            onRemove: () => {
              setFilter("devices_mac", null)
            },
          },
          {
            id: "include_inactive",
            active: !!filter.devices_include_inactive,
            label: "Include inactive",
            value: filter.devices_include_inactive ? "True" : "False",
            render: (
              <FilterContent>
                <MText variant="subtitleS" marginBottom={spacing.S}>
                  Include inactive devices
                </MText>
                <Switch
                  checked={!!filter.devices_include_inactive}
                  onChange={(_, checked) =>
                    setFilter("devices_include_inactive", checked)
                  }
                />
              </FilterContent>
            ),
            onRemove: () => {
              setFilter("devices_include_inactive", false)
            },
            alwaysVisible: true,
            removeLabel: "Reset",
          },
          {
            id: "offline",
            active: filter.devices_offline !== null,
            label: "Status",
            value: filter.devices_offline
              ? "Offline devices only"
              : "Online devices only",
            render: (
              <FilterContent>
                <RadioGroup
                  onChange={(e) =>
                    setFilter("devices_offline", e.target.value === "true")
                  }
                  value={String(filter.devices_offline)}
                >
                  <FormControlLabel
                    value="false"
                    label="Online devices only"
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value="true"
                    label="Offline devices only"
                    control={<Radio />}
                  />
                </RadioGroup>
              </FilterContent>
            ),
            onRemove: () => {
              setFilter("devices_offline", null)
            },
          },
          {
            id: "hardware_version",
            label: "Hardware version",
            value: selectedHardwareVersion?.name ?? "",
            active: filter.devices_hardwareVersion !== null,
            render: (
              <FilterContent>
                {hardwareVersions && (
                  <Combobox
                    label="Hardware version"
                    options={hardwareVersions.map((version) => ({
                      label: `${version.name} (${version.version_number})`,
                      value: version.version_number.toString(),
                      selectedLabelText: version.name,
                    }))}
                    selectedValue={
                      filter.devices_hardwareVersion?.toString() ?? ""
                    }
                    initialInput={selectedHardwareVersion?.name}
                    onChange={(value) => {
                      if (value !== "") {
                        setFilter("devices_hardwareVersion", Number(value))
                        setSearchedVersionNumber("")
                      }
                    }}
                    onSearch={setSearchedVersionNumber}
                  />
                )}
              </FilterContent>
            ),
            onRemove: () => setFilter("devices_hardwareVersion", null),
          },
          {
            id: "device_firmware",
            active: !!filter.devices_firmware,
            label: "Firmware",
            value: filter.devices_firmware || "",
            render: (
              <FilterContent>
                {showFirmwareFilter ? (
                  <SearchFilter
                    initialValue={filter.devices_firmware ?? undefined}
                    placeholder="Serach by firmware"
                    onChange={(v) => setFilter("devices_firmware", v)}
                  />
                ) : (
                  <MText>Not supported</MText>
                )}
              </FilterContent>
            ),
            onRemove: () => {
              setFilter("devices_firmware", null)
            },
          },
          {
            id: "device_tag",
            active: !!filter.devices_tag,
            label: "Tag",
            value: filter.devices_tag || "",
            render: (
              <FilterContent>
                {showTagFilter ? (
                  <SearchFilter
                    initialValue={filter.devices_tag ?? undefined}
                    placeholder="Serach by tag"
                    onChange={(v) => setFilter("devices_tag", v)}
                  />
                ) : (
                  <MText>Not supported</MText>
                )}
              </FilterContent>
            ),
            onRemove: () => {
              setFilter("devices_tag", null)
            },
          },
        ]}
      />
    </FilterWrapper>
  )
}

const FilterWrapper = styled.div`
  display: block;
  margin-top: ${spacing.M};
`

const FilterContent = styled.div`
  padding: ${spacing.XS};
`
